import { toast } from "react-toastify";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { api } from "../helpers/api";

export const initApp = () => {
  const token = localStorage.getItem("davrToken");
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const useAuth = create(
  devtools((set) => ({
    user: {},
    loading: false,
    confirmCodeLoading: false,
    message: null,
    success: false,
    isGuest: false,
    step: 1,
    login: async (params) => {
      set({ loading: true, message: null });
      const response = await api.post("/user/login", params);
      const token = await response?.data?.data?.user?.auth_key;
      const success = await response?.data?.success;
      set({
        user: await response?.data?.data?.user,
        loading: false,
        success: success,
        message: null,
      });
      set({
        message: response?.data?.error?.message || response?.data?.message,
        loading: false,
        success: success,
      });
      if (success === false) {
        toast.error(useAuth.getState().message);
      }
      return response;
    },
    confirmCode: async (params) => {
      set({ confirmCodeLoading: true });
      const response = await api.post("/user/confirm-code", params);
      set({ confirmCodeLoading: false });
      return response?.data;
    },
    resendSms: async (params) => {
      set({ resendSmsLoading: true });
      const response = await api.post("/user/resend-sms", params);
      set({ resendSmsLoading: false });
      return response?.data;
    },
    fetchMe: async () => {
      set({ loading: true, message: null });
      const response = await api.get("/user/me");
      const me = await response?.data?.data;
      const isGuest = await response?.data?.success;
      set({
        user: me,
        isGuest: isGuest,
        loading: false,
      });
      set({
        message: "Error",
        isGuest: isGuest,
        loading: false,
      });
      if (response?.data?.success === false) {
        localStorage.removeItem("davrToken");
        // window.history.pushState("", "", "/login");
      } else {
      }
    },
    // Set success
    setSuccess: (params) => {
      set((state) => ({ success: params }));
    },
    // Set step
    setStep: (params) => {
      set((state) => ({ step: params }));
    },
    // Logout
    logout: () => {
      set({ user: {} });
      localStorage.removeItem("davrToken");
    },
  }))
);

export default useAuth;
