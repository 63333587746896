import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../store/auth";

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("davrToken");
  const { isGuest } = useAuth();

  const [isIdle, setIsIdle] = useState(false);

  useEffect(() => {
    let timer;

    const resetTimer = () => {
      setIsIdle(false);
      clearTimeout(timer);
      timer = setTimeout(() => {
        setIsIdle(true);
        localStorage.removeItem("davrToken");
        navigate("/login");
      }, 3600000 * 3);
    };

    // Faoliyatni kuzatish
    const activityEvents = ["mousemove", "keydown"];
    activityEvents.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });
    resetTimer();
    return () => {
      activityEvents.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (!token && !isGuest) {
      navigate("/login");
    }
  }, [token, isGuest]);

  return token && children;
};

export default PrivateRoute;
