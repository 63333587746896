import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import usePay from "../../store/pay";
import QRCode from "qrcode";
Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
  fontWeight: "normal",
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    borderLeft: "1px solid #000",
    borderIrght: "1px solid #000",
    marginTop: 8,
  },
  section: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "0.5px solid #000",
  },
  block1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: "20%",
    position: "relative",
  },
  block2: {
    width: "60%",
    borderLeft: "0.5px solid #000",
    borderRight: "0.5px solid #000",
    position: "relative",
  },
  block3: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: "20%",
  },
  text: {
    fontFamily: "Roboto",
    fontSize: 8,
  },
  centerText: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "Roboto",
    fontSize: 8,
    paddingVertical: 5,
    paddingHorizontal: 8,
    borderBottom: "0.5px solid #000",
  },
  button: {
    color: "#00bf63",
    backgroundColor: "#fff",
    border: "2px dashed #00bf63",
    paddingHorizontal: 10,
    paddingVertical: 4,
    borderRadius: 4,
    fontFamily: "Roboto",
    fontSize: 12,
    marginTop: 8,
    position: "absolute",
    right: "-40px",
    transform: "rotate(-90deg)",
    width: "120px",
  },
  buttonRed: {
    color: "#C10000",
    backgroundColor: "#fff",
    border: "2px dashed #C10000",
    paddingHorizontal: 10,
    paddingVertical: 4,
    borderRadius: 4,
    fontFamily: "Roboto",
    fontSize: 12,
    marginTop: 8,
    position: "absolute",
    right: "-40px",
    transform: "rotate(-90deg)",
    width: "120px",
  },
  duplicateText: {
    position: "absolute",
    top: "70px",
    left: "100px",
    color: "#C10000",
    border: "2px dashed #C10000",
    textTransform: "uppercase",
    paddingHorizontal: 14,
    paddingVertical: 4,
    fontFamily: "Roboto",
    fontSize: 26,
    fontWeight: "thin",
    zIndex: 1,
    transform: "rotate(-25deg)",
  },
  centerFooter: {
    display: "flex",
    flexDirection: "row",
  },
  centerFooterDiv: {
    flex: 1,
    borderRight: "0.5px solid #a9a4a4",
  },
  footerLabel: {
    fontSize: 6,
    paddingVertical: 2,
    paddingHorizontal: 8,
    fontFamily: "Roboto",
    textAlign: "center",
    borderBottom: "0.5px solid #a9a4a4",
  },
  footerText: {
    fontSize: 6,
    paddingVertical: 4,
    paddingHorizontal: 10,
    fontWeight: "bold",
    fontFamily: "Roboto",
    textAlign: "center",
  },
  image: {
    width: "50px",
    height: "50px",
    objectFit: "contain",
    marginBottom: 6,
    marginRight: 8,
  },
  qrcode: {
    width: "80px",
    height: "80px",
    display: "flex",
  },
});

const url = "https://davrsmart.uz/dashboard/receipt";

const Check = ({ data, showDuplicateText }) => {
  const [dataMemo, setDataMemo] = useState(data);

  useEffect(() => {
    setDataMemo(data);
  }, [data]);

  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          {dataMemo?.length > 0 &&
            dataMemo?.map((payChequeDetailsData, idx) => {
              return (
                <View key={idx} style={styles.section}>
                  <View style={styles.block1}>
                    {
                      <Image
                        style={styles.image}
                        src={QRCode.toDataURL(
                          url +
                            "/" +
                            payChequeDetailsData?.partner_transaction_id
                        )}
                      />
                    }
                    <Text
                      style={
                        payChequeDetailsData?.status?.int == 2
                          ? styles.button
                          : styles.buttonRed
                      }
                    >
                      {payChequeDetailsData?.status?.int == 2
                        ? "Оплачено"
                        : payChequeDetailsData?.status?.string}
                    </Text>
                  </View>
                  <View style={styles.block2}>
                    {showDuplicateText && (
                      <Text style={styles.duplicateText}>Дубликат</Text>
                    )}
                    <View style={styles.centerText}>
                      <Text>Лицевой счет</Text>
                      <Text>{payChequeDetailsData?.account}</Text>
                    </View>
                    <View style={styles.centerText}>
                      <Text>Номер транзакции “DAVR SMART SERVICE”</Text>
                      <Text>{payChequeDetailsData?.id}</Text>
                    </View>
                    <View style={styles.centerText}>
                      <Text>Номер транзакции банка</Text>
                      <Text>
                        {payChequeDetailsData?.partner_transaction_id}
                      </Text>
                    </View>
                    <View style={styles.centerText}>
                      <Text>Начислено</Text>
                      <Text>
                        {payChequeDetailsData?.amount?.toLocaleString("ru-RU")}{" "}
                        UZS
                      </Text>
                    </View>
                    <View style={styles.centerText}>
                      <Text>Комиссия</Text>
                      <Text>
                        {payChequeDetailsData?.commission_amount?.toLocaleString(
                          "ru-RU"
                        )}{" "}
                        UZS
                      </Text>
                    </View>
                    <View style={styles.centerText}>
                      <Text>Услуга</Text>
                      <Text>{payChequeDetailsData?.vendor_id?.name}</Text>
                    </View>
                    <View style={styles.centerText}>
                      <Text>Плательщик</Text>
                      <Text>{payChequeDetailsData?.payer_full_name}</Text>
                    </View>
                    <View style={styles.centerText}>
                      <Text>Отделение</Text>
                      <Text>{payChequeDetailsData?.structure_id?.name}</Text>
                    </View>
                    <View style={styles.centerFooter}>
                      <View style={styles.centerFooterDiv}>
                        <Text style={styles.footerLabel}>Оператор</Text>
                        <Text style={styles.footerText}>
                          {payChequeDetailsData?.user_id?.first_name}{" "}
                          {payChequeDetailsData?.user_id?.last_name}
                        </Text>
                      </View>
                      <View style={styles.centerFooterDiv}>
                        <Text style={styles.footerLabel}>Дата платежа</Text>
                        <Text style={styles.footerText}>
                          {payChequeDetailsData?.created_at?.dateFormat}
                        </Text>
                      </View>
                      <View style={styles.centerFooterDiv}>
                        <Text style={styles.footerLabel}>Обшая сумма</Text>
                        <Text style={styles.footerText}>
                          {payChequeDetailsData?.amount?.toLocaleString(
                            "ru-RU"
                          )}{" "}
                          UZS
                        </Text>
                      </View>
                      <View style={styles.centerFooterDiv}>
                        <Text style={styles.footerLabel}>Подпись</Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.block3}>
                    {payChequeDetailsData?.ofd_cheque_url ? (
                      <Image
                        style={styles.qrcode}
                        src={QRCode.toDataURL(
                          payChequeDetailsData?.ofd_cheque_url
                        )}
                      />
                    ) : (
                      <Text style={styles.text}>
                        OFD chek sms orqali yuboriladi
                      </Text>
                    )}
                  </View>
                </View>
              );
            })}
        </Page>
      </Document>
    </>
  );
};

export default Check;
