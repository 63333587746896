export const IconSelect = () => {
  return (
    <svg
      width="9"
      height="10"
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.5 10L0 6H9L4.5 10Z" fill="black" />
      <path d="M4.5 0L9 4L0 4L4.5 0Z" fill="black" />
    </svg>
  );
};

export const IconSearch = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 19L14.6569 14.6569M14.6569 14.6569C16.1046 13.2091 17 11.2091 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17C11.2091 17 13.2091 16.1046 14.6569 14.6569Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconSeal = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 21C5.89543 21 5 20.1046 5 19V3H14L19 8V19C19 20.1046 18.1046 21 17 21H7Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 13V17"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 15H14"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M13 3V9H19" stroke="white" strokeLinejoin="round" />
    </svg>
  );
};

export const IconClose = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="26" height="26" rx="4.5" stroke="white" />
      <g clipPath="url(#clip0_436_4469)">
        <path
          d="M9 9L19 19M9 19L19 9"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_436_4469">
          <rect
            width="14"
            height="15"
            fill="white"
            transform="translate(7 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const IconPrint = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 18H6C4.89543 18 4 17.1046 4 16V7H20V16C20 17.1046 19.1046 18 18 18H16"
        stroke="#292929"
        strokeLinejoin="round"
      />
      <path d="M8 3H16V7H8V3Z" stroke="#292929" strokeLinejoin="round" />
      <path
        d="M12 11H8"
        stroke="#292929"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 15H16V21H8V15Z" stroke="#292929" strokeLinejoin="round" />
    </svg>
  );
};

export const IconDownload = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_436_4511)">
        <path
          d="M17 9L12 4M12 4L7 9M12 4L12 17"
          stroke="#292929"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 20H18"
          stroke="#292929"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_436_4511">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const IconUser = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 15.9999C15.4144 15.9999 16.771 15.438 17.7712 14.4378C18.7714 13.4376 19.3333 12.0811 19.3333 10.6666C19.3333 9.2521 18.7714 7.89554 17.7712 6.89535C16.771 5.89515 15.4144 5.33325 14 5.33325C12.5855 5.33325 11.2289 5.89515 10.2287 6.89535C9.22853 7.89554 8.66663 9.2521 8.66663 10.6666C8.66663 12.0811 9.22853 13.4376 10.2287 14.4378C11.2289 15.438 12.5855 15.9999 14 15.9999ZM14 14.6666C14.5252 14.6666 15.0454 14.5631 15.5307 14.3621C16.016 14.1611 16.457 13.8664 16.8284 13.495C17.1998 13.1236 17.4945 12.6826 17.6955 12.1973C17.8965 11.712 18 11.1919 18 10.6666C18 10.1413 17.8965 9.62115 17.6955 9.13585C17.4945 8.65055 17.1998 8.20959 16.8284 7.83816C16.457 7.46672 16.016 7.17209 15.5307 6.97107C15.0454 6.77005 14.5252 6.66659 14 6.66659C12.9391 6.66659 11.9217 7.08801 11.1715 7.83816C10.4214 8.5883 9.99996 9.60572 9.99996 10.6666C9.99996 11.7275 10.4214 12.7449 11.1715 13.495C11.9217 14.2452 12.9391 14.6666 14 14.6666Z"
        fill="#D1D1D1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.3333 14C27.3333 21.364 21.364 27.3333 14 27.3333C6.63596 27.3333 0.666626 21.364 0.666626 14C0.666626 6.63596 6.63596 0.666626 14 0.666626C21.364 0.666626 27.3333 6.63596 27.3333 14ZM20.42 24.14C18.5006 25.3583 16.2734 26.0036 14 26C11.6721 26.0037 9.39379 25.3271 7.44529 24.0533C7.28529 23.8533 7.12329 23.6466 6.95863 23.4333C6.76831 23.1833 6.66569 22.8775 6.66663 22.5633C6.66663 21.8453 7.18263 21.2433 7.87263 21.1426C12.436 20.476 15.578 20.5333 20.1473 21.166C20.4782 21.2139 20.7806 21.38 20.9986 21.6335C21.2166 21.8871 21.3355 22.2109 21.3333 22.5453C21.3333 22.8653 21.2233 23.176 21.0246 23.4186C20.82 23.668 20.618 23.9086 20.42 24.14ZM22.6566 22.3106C22.55 21.0626 21.6026 20.0213 20.33 19.8453C15.6526 19.198 12.3833 19.136 7.67996 19.8233C6.39996 20.01 5.45663 21.0606 5.34463 22.312C3.19484 20.0793 1.99572 17.0993 1.99996 14C1.99996 7.37263 7.37263 1.99996 14 1.99996C20.6273 1.99996 26 7.37263 26 14C26.0042 17.0987 24.8056 20.0781 22.6566 22.3106Z"
        fill="#D1D1D1"
      />
    </svg>
  );
};

export const IconEye = () => {
  return (
    <svg
      width="29"
      height="18"
      viewBox="0 0 29 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3334 16C19.6667 16 24.28 13.04 26.6534 8.66667C24.28 4.29333 19.6667 1.33333 14.3334 1.33333C9.00002 1.33333 4.38669 4.29333 2.01335 8.66667C4.38669 13.04 9.00002 16 14.3334 16ZM14.3334 0C20.4134 0 25.6667 3.53333 28.1467 8.66667C25.6667 13.8 20.4134 17.3333 14.3334 17.3333C8.25335 17.3333 3.00002 13.8 0.52002 8.66667C3.00002 3.53333 8.25335 0 14.3334 0ZM14.3334 2.66667C17.6667 2.66667 20.3334 5.33333 20.3334 8.66667C20.3334 12 17.6667 14.6667 14.3334 14.6667C11 14.6667 8.33335 12 8.33335 8.66667C8.33335 5.33333 11 2.66667 14.3334 2.66667ZM14.3334 4C13.0957 4 11.9087 4.49167 11.0335 5.36684C10.1584 6.24201 9.66669 7.42899 9.66669 8.66667C9.66669 9.90434 10.1584 11.0913 11.0335 11.9665C11.9087 12.8417 13.0957 13.3333 14.3334 13.3333C15.571 13.3333 16.758 12.8417 17.6332 11.9665C18.5084 11.0913 19 9.90434 19 8.66667C19 7.42899 18.5084 6.24201 17.6332 5.36684C16.758 4.49167 15.571 4 14.3334 4Z"
        fill="#D1D1D1"
      />
    </svg>
  );
};

export const IconClear = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0.25H20V2.38867L12.5 9.88867V17.75H7.5V9.88867L0 2.38867V0.25ZM18.75 1.86133V1.5H1.25V1.86133L8.75 9.36133V16.5H11.25V9.36133L18.75 1.86133ZM19.1113 11.5L20 12.3887L17.7539 14.625L20 16.8613L19.1113 17.75L16.875 15.5039L14.6387 17.75L13.75 16.8613L15.9961 14.625L13.75 12.3887L14.6387 11.5L16.875 13.7461L19.1113 11.5Z"
        fill="#FF0000"
      />
    </svg>
  );
};
