import { toast } from "react-toastify";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { api, formData } from "../helpers/api";
import axios from "axios";

const useTransaction = create(
  devtools((set) => ({
    transactions: [],
    lastTransactions: [],
    savedReportList: [],
    viewTransactionWithoutData: {},
    viewTransactionWithoutDataForCommission: {},
    loading: false,
    lastTransactionsLoading: false,
    savedReportListLoading: false,
    savedReportSaveLoading: false,
    viewTransactionWithoutDataMessage: null,
    message: null,
    success: false,
    modal: false,
    savedReportSaveSuccess: false,
    viewTransactionWithoutForCommissionLoading: false,
    // Transaction
    fetchTransactions: async (params) => {
      try {
        set({ loading: true, message: null });
        const response = await api.get(`/transaction/list`, { params });
        set({
          transactions: await response?.data?.data,
          loading: false,
        });
      } catch (err) {
        set({
          message: "Error",
          loading: false,
        });
      }
    },
    fetchLastTransactions: async () => {
      try {
        set({ lastTransactionsLoading: true, message: null });
        const response = await api.post(`/transaction/last`);
        set({
          lastTransactions: await response?.data?.data,
          lastTransactionsLoading: false,
        });
      } catch (err) {
        set({
          message: "Error",
          lastTransactionsLoading: false,
        });
      }
    },
    fetchSavedReports: async (params) => {
      set({ savedReportListLoading: true, message: null });
      const response = await api.get(`/saved-report/list`, { params });
      const data = await response?.data?.data?.dataProvider;
      set({
        savedReportList: data,
        savedReportListLoading: false,
        savedReportSaveSuccess: false,
      });
      set({
        message: "Error",
        savedReportListLoading: false,
      });
    },
    savedReportSave: async (params) => {
      set({ savedReportSaveLoading: true, message: null });
      const response = await api.post("/saved-report/save", params);
      const success = await response?.data?.success;
      const message = await response?.data?.message;
      const errorMessage = await response?.data?.error?.message;
      set({
        savedReportSaveLoading: false,
        savedReportSaveSuccess: success,
      });
      set({
        message: "Error",
        savedReportSaveLoading: false,
        savedReportSaveSuccess: success,
      });
      if (success) {
        toast.success("Успешно создано");
        set({ modal: false });
      }
      if (!success) {
        toast.error(message || errorMessage);
      }
    },
    fetchDetailSavedReports: async (id) => {
      const response = await api.get(`/saved-report/view?id=${id}`);
    },
    fetchDetailTransactionWithoutToken: async (params) => {
      set({
        viewTransactionWithoutLoading: true,
        viewTransactionWithoutDataMessage: null,
      });
      const response = await api.post("/open-api/cheque", params);
      const success = await response?.data?.success;
      const message =
        (await response?.data?.error?.message) || response?.data?.message;
      set({
        viewTransactionWithoutData: response?.data,
        viewTransactionWithoutLoading: false,
        savedReportSaveSuccess: success,
      });
      set({
        message: "Error",
        viewTransactionWithoutLoading: false,
        savedReportSaveSuccess: success,
        viewTransactionWithoutDataMessage: null,
      });
      if (!success) {
        set({
          viewTransactionWithoutData: {},
          viewTransactionWithoutDataMessage: message,
        });
      }
    },
    fetchDetailTransactionWithoutTokenForCommission: async (id) => {
      set({
        viewTransactionWithoutForCommissionLoading: true,
        viewTransactionWithoutDataForCommissionMessage: null,
      });
      const response = await api.post("/open-api/cheque", {
        transactionId: id,
      });
      const message =
        (await response?.data?.error?.message) || response?.data?.message;
      const success = await response?.data?.success;
      set({
        viewTransactionWithoutDataForCommission: response?.data,
        viewTransactionWithoutForCommissionLoading: false,
        savedReportSaveSuccess: success,
      });
      set({
        message: "Error",
        viewTransactionWithoutForCommissionLoading: false,
        viewTransactionWithoutDataForCommissionMessage: null,
      });
      if (!success) {
        set({
          viewTransactionWithoutDataForCommission: {},
          viewTransactionWithoutDataForCommissionMessage: message,
        });
      }
    },
    editTransaction: async (params) => {
      try {
        const response = await api.post("/transaction/update", params, {
          timeout: 60000,
        });
        return response;
      } catch (error) {
        if (axios.isAxiosError(error) && error.code === "ECONNABORTED") {
          toast.error(
            "Запрос не получил ответа. Пожалуйста, попробуйте еще раз!"
          );
        }
      }
    },
    openModal: () => {
      set({ modal: true });
    },
    closeModal: () => {
      set({ modal: false });
    },
  }))
);

export default useTransaction;
