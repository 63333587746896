import { lazy } from "react";
const Dashboard = lazy(() => import("../views/dashboard/Dashboard"));
const Statement = lazy(() => import("../views/statement/Statement"));
const Favorites = lazy(() => import("../views/favorites/Favorites"));
const Transaction = lazy(() => import("../views/transaction/Transaction"));
const Report = lazy(() => import("../views/report/Report"));
const ReportByRegion = lazy(() =>
  import("../views/reportByRegion/ReportByRegion")
);
const TransactionRegister = lazy(() =>
  import("../views/transactionRegister/TransactionRegister")
);
const Balance = lazy(() => import("../views/balance/Balance"));
const Payment = lazy(() => import("../views/payment/Payment"));
const Deposit = lazy(() => import("../views/deposit/Deposit"));
const Video = lazy(() => import("../views/video/Videos"));
const Faq = lazy(() => import("../views/faq/Faq"));

export const routes = [
  { path: "/dashboard", element: Dashboard, exact: true },
  { path: "/dashboard/statement", element: Statement },
  { path: "/dashboard/favorites", element: Favorites },
  { path: "/dashboard/transaction", element: Transaction },
  { path: "/dashboard/report", element: Report },
  // { path: "/dashboard/report-by-region", element: ReportByRegion },
  { path: "/dashboard/transaction-register", element: TransactionRegister },
  // { path: "/dashboard/balance", element: Balance },
  { path: "/dashboard/payment", element: Payment },
  { path: "/dashboard/deposit", element: Deposit },
  { path: "/dashboard/video", element: Video },
  { path: "/dashboard/faq", element: Faq },
];
