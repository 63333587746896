import { toast } from "react-toastify";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { api } from "../helpers/api";
import axios from "axios";

const usePay = create(
  devtools((set) => ({
    payVendorFormData: [],
    isCommission: false,
    payByCashData: {},
    payCommissionByCashData: {},
    payInfoData: {},
    payPrepareData: {},
    payConfirmData: {},
    payChequeDetailsData: [],
    payCommissionChequeDetailsData: [],
    payCommissionInfoData: {},
    vendorFormLoading: false,
    loading: false,
    payChequeDetailsLoading: false,
    payByCashDataLoading: false,
    payCommissionByCashDataLoading: false,
    message: null,
    vendorFormSuccess: false,
    success: false,
    modalStep: 0,
    globalModalLoading: false,
    payInfoIsAborted: false,
    // Pay methods
    payVendorForm: async (params) => {
      set({
        vendorFormLoading: true,
        globalModalLoading: true,
        message: null,
        isCommission: false,
        payChequeDetailsData: [],
        payCommissionChequeDetailsData: [],
      });
      try {
        const response = await api.post("/pay/vendor-form", params, {
          timeout: 40000,
        });
        const data = await response?.data?.data;
        const success = await response?.data?.success;
        set({
          payVendorFormData: data,
          vendorFormLoading: false,
          globalModalLoading: false,
          vendorFormSuccess: success,
        });
      } catch (error) {
        if (axios.isAxiosError(error) && error.code === "ECONNABORTED") {
          toast.error(
            "Запрос не получил ответа. Пожалуйста, попробуйте еще раз!"
          );
          set({
            modalStep: 0,
            payVendorFormData: [],
            vendorFormSuccess: false,
          });
        }
        set({
          vendorFormLoading: false,
          globalModalLoading: false,
          message: "Error",
          vendorFormSuccess: false,
        });
      }
    },
    payInfo: async (params) => {
      set({ loading: true, message: null, globalModalLoading: true });
      try {
        const response = await api.post("/pay/info", params, {
          timeout: 20000,
        });
        const data = await response?.data?.data;
        const success = await response?.data?.success;
        const message = await response?.data?.message;
        const commission = await response?.data?.data?.isCommission;
        set({
          payInfoData: data,
          isCommission: commission,
          loading: false,
          globalModalLoading: false,
          success: success,
        });
        if (!success) {
          toast.error(message);
        }
        if (success) {
          set((state) => ({ modalStep: 1 }));
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("axios request cancelled", error.message);
        }
        if (axios.isAxiosError(error) && error.code === "ECONNABORTED") {
          toast.error(
            "Запрос не получил ответа. Пожалуйста, попробуйте еще раз!"
          );
        }
        set({
          loading: false,
          globalModalLoading: false,
        });
      }
    },
    payByCash: async (params) => {
      set({
        payByCashDataLoading: true,
        message: null,
        globalModalLoading: true,
      });
      try {
        const response = await api.post("/pay/pay-by-cash", params);
        const data = await response?.data?.data;
        const success = await response?.data?.success;
        const message = await response?.data?.success;
        const errorMessage = await response?.data?.error?.message;
        set({
          payByCashData: data,
          payByCashDataLoading: false,
          globalModalLoading: false,
          success: success,
        });
        if (!success) {
          toast.error(errorMessage || message);
        }
        if (success) {
          set((state) => ({ modalStep: 2 }));
        }
      } catch (error) {
        if (axios.isAxiosError(error) && error.code === "ECONNABORTED") {
          toast.error(
            "Запрос не получил ответа. Пожалуйста, проверьте список транзакций!"
          );
        }
        set({
          payByCashDataLoading: false,
          globalModalLoading: false,
          message: "Error",
        });
      }
    },
    payCommissionByCash: async (params) => {
      set({ payCommissionByCashDataLoading: true, message: null });
      const response = await api.post("/pay/pay-by-cash", params);
      const data = await response?.data?.data;
      const success = await response?.data?.success;
      const message = await response?.data?.success;
      const errorMessage = await response?.data?.error?.message;
      set({
        payCommissionByCashData: data,
        payCommissionByCashDataLoading: false,
        success: success,
      });
      set({
        payCommissionByCashDataLoading: false,
        message: "Error",
        success: success,
      });
      if (!success) {
        toast.error(errorMessage || message);
      }
      if (success) {
        set((state) => ({ modalStep: 3 }));
      }
    },
    payCommissionInfo: async (params) => {
      set({ loading: true, message: null });
      const response = await api.post("/pay/commission-info", params);
      const data = await response?.data?.data;
      const success = await response?.data?.success;
      const message = await response?.data?.success;
      const errorMessage = await response?.data?.error?.message;
      set({ payCommissionInfoData: data, loading: false, success: success });
      set({
        loading: false,
        message: "Error",
        success: success,
      });
      if (!success) {
        toast.error(errorMessage || message);
      }
    },

    payChequeDetails: async (params) => {
      set({ loading: true, message: null, payChequeDetailsLoading: true });
      const response = await api.post("/pay/cheque-details", params);
      const data = await response?.data?.data;
      const success = await response?.data?.success;
      const message = await response?.data?.success;
      const errorMessage = await response?.data?.error?.message;
      set({
        payChequeDetailsData: data,
        loading: false,
        payChequeDetailsLoading: false,
        success: success,
      });
      set({
        loading: false,
        payChequeDetailsLoading: false,
        message: "Error",
        success: success,
      });
      if (!success) {
        toast.error(errorMessage || message);
      }
    },
    payCommissionChequeDetails: async (params) => {
      set({ loading: true, message: null });
      const response = await api.post("/pay/cheque-details", params);
      const data = await response?.data?.data;
      const success = await response?.data?.success;
      const message = await response?.data?.success;
      const errorMessage = await response?.data?.error?.message;
      set({
        payCommissionChequeDetailsData: data,
        loading: false,
        success: success,
      });
      set({
        loading: false,
        message: "Error",
        success: success,
      });
      if (!success) {
        toast.error(errorMessage || message);
      }
    },

    // payConfirm: async (params) => {
    //   set({ loading: true, message: null });
    //   const response = await api.post("/pay/confirm", params);
    //   const data = await response?.data;
    //   const success = await response?.data?.success;
    //   const message = await response?.data?.success;
    //   const errorMessage = await response?.data?.error?.message;
    //   set({ payConfirmData: data, loading: false, success: success });
    //   set({
    //     loading: false,
    //     message: "Error",
    //     success: success,
    //   });
    //   if (!success) {
    //     toast.error(errorMessage || message);
    //   }
    //   if (success) {
    //     set((state) => ({ modalStep: state.modalStep + 1 }));
    //   }
    // },
    clearData: () => {
      set({ payChequeDetailsData: [], payCommissionChequeDetailsData: [] });
    },
    // Modal step
    nextModal: () => {
      set((state) => ({ modalStep: state.modalStep + 1 }));
    },
    prevModal: () => {
      set((state) => ({ modalStep: state.modalStep - 1 }));
    },
    clearModal: () => {
      set((state) => ({ modalStep: 0 }));
    },
    closeModal: () => {
      set((state) => ({ modalStep: 0, vendorFormSuccess: false }));
    },
    clearCommission: () => {
      set({ isCommission: false });
    },
  }))
);

export default usePay;
