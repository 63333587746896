import { React, lazy } from "react";
const Navbar = lazy(() => import("../layouts/navbar/Navbar"));
const Sidebar = lazy(() => import("../layouts/sidebar/Sidebar"));

const Layout = ({ children }) => {
  return (
    <>
      <div>
        <Sidebar />
        <div className="page">
          <Navbar />
          <div className="content">{children}</div>
        </div>
      </div>
    </>
  );
};

export default Layout;
