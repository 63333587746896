import React, { useEffect, useMemo, useState } from "react";
import QR from "../../assets/images/qr.png";
import MVD from "../../assets/images/cart1.png";
import BaseButton from "../../components/button/BaseButton";
import "../../assets/scss/_payment.scss";
// import QRCode from "react-qr-code";
import QRCode from "qrcode.react";
import Logo from "../../assets/images/Logo.png";

const PaymentConfirmCheck = ({ item }) => {
  const qr = useMemo(() => item, [item]);
  return (
    <div className="payment-confirm-check">
      <div className="left">
        {/* {item?.vendor_id?.logo && <img src={item?.vendor_id?.logo} alt="" />} */}
        {/* <img src={Logo} alt="" /> */}
        <QRCode
          id={`https://davrsmart.uz/dashboard/receipt/${qr?.partner_transaction_id}`}
          value={`https://davrsmart.uz/dashboard/receipt/${qr?.partner_transaction_id}`}
          renderAs="canvas"
          size={80}
          className="mr-4"
        />
        <BaseButton
          name={item?.status?.int == 2 ? "Оплачено" : item?.status?.string}
          className={`button-success !bg-[#fff] !border-2 ${
            item?.status?.int === 2
              ? "!border-[#1ed17b] !text-[#1ed17b]"
              : "!border-[#FF2020] !text-[#FF2020]"
          } !text-xl !border-dashed !h-[38px] md:absolute right-[-75px] md:rotate-[-90deg] !w-[200px]`}
          style={{ fontSize: "12px" }}
        />
      </div>
      <div className="center">
        <div className="item">
          <div className="item-left">Лицевой счет</div>
          <div className="item-right">{item?.account}</div>
        </div>
        <div className="item">
          <div className="item-left">Номер транзакции “DAVR SMART SERVICE”</div>
          <div className="item-right">{item?.id}</div>
        </div>
        <div className="item">
          <div className="item-left">Номер транзакции банка</div>
          <div className="item-right">{item?.partner_transaction_id}</div>
        </div>
        <div className="item">
          <div className="item-left">Начислено:</div>
          <div className="item-right">
            {item?.amount?.toLocaleString("ru-RU")} {item?.currency?.string}
          </div>
        </div>
        <div className="item">
          <div className="item-left">Комиссия:</div>
          <div className="item-right">
            {item?.commission_amount?.toLocaleString("ru-RU")}{" "}
            {item?.currency?.string}
          </div>
        </div>
        <div className="item">
          <div className="item-left">Оплаченая сумма:</div>
          <div className="item-right" style={{ color: "#1ed17b" }}>
            {item?.amount?.toLocaleString("ru-RU")} {item?.currency?.string}
          </div>
        </div>
        <div className="item">
          <div className="item-left">Услуга:</div>
          <div className="item-right">{item?.vendor_id?.name}</div>
        </div>
        <div className="item">
          <div className="item-left">Плательщик:</div>
          <div className="item-right">{item?.payer_full_name}</div>
        </div>
        <div className="item">
          <div className="item-left">Отделение:</div>
          <div className="item-right">{item?.structure_id?.name}</div>
        </div>
        <div className="center-footer">
          <div>
            <div className="footer-label">Оператор</div>
            <div className="footer-text">
              {item?.user_id?.first_name} {item?.user_id?.last_name}
            </div>
          </div>
          <div>
            <div className="footer-label">Дата платежа</div>
            <div className="footer-text">{item?.created_at?.dateFormat}</div>
          </div>
          <div>
            <div className="footer-label">Обшая сумма</div>
            <div className="footer-text">
              {item?.amount?.toLocaleString("ru-RU")} {item?.currency?.string}
            </div>
          </div>
          <div>
            <div className="footer-label">Подпись</div>
            <div className="footer-text"></div>
          </div>
        </div>
      </div>
      <div className="right">
        {qr?.ofd_cheque_url ? (
          <QRCode
            id={qr?.ofd_cheque_url}
            // className="w-[100px] h-[100px] m-auto"
            value={qr?.ofd_cheque_url}
            renderAs="canvas"
          />
        ) : (
          "OFD chek sms orqali yuboriladi"
        )}
        {/* <img className="qr" src={QR} alt="" /> */}
      </div>
    </div>
  );
};

export default PaymentConfirmCheck;
