import React from "react";
import "../../assets/scss/_components.scss";

const BaseButton = ({
  name,
  className,
  style,
  handleSubmit,
  disabled,
  icon,
}) => {
  // 3 buttonName => button-success, button-primary, button-slate
  return (
    <button
      className={`button ${className}`}
      onClick={handleSubmit}
      style={style}
      disabled={disabled}
    >
      {name}
      {icon && icon}
    </button>
  );
};

export default BaseButton;
