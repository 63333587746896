import { lazy } from "react";
import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import { v4 } from "uuid";
import Layout from "./routes/Layout";
import PrivateRoute from "./routes/PrivateRoute";
import { routes } from "./routes/_routes";
import "aos/dist/aos.css";
import Aos from "aos";
import { Navigate } from "react-router-dom";
import { initApp } from "./store/auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DetailTransactionsForCheck from "./views/detailTransaction/DetailTransactionsForCheck";
import Index from "./constants";
const mode = process.env.NODE_ENV;

// import Pages
const Login = lazy(() => import("./views/auth/Login"));
const DetailTransaction = lazy(() =>
  import("./views/detailTransaction/DetailTransaction")
);
const DetailTransactionForSelf = lazy(() =>
  import("./views/detailTransaction/DetailTransactionForSelf")
);
const NotFound = lazy(() => import("./views/notfound/NotFound"));

const loading = <LinearProgress />;

const App = () => {
  initApp();
  window.addEventListener("load", () => {
    Aos.init();
  });

  console.log(`This is a ${mode} mode`);

  return (
    <BrowserRouter>
      <Index />
      <ToastContainer position="top-right" />
      <Suspense fallback={loading}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/dashboard/receipt/:id"
            element={<DetailTransaction />}
          />
          <Route
            path="/dashboard/transactions/for-check"
            element={<DetailTransactionsForCheck />}
          />
          <Route
            path="/dashboard/transaction/f4d5b76a2418eba4baeabc1ed9142b54/:id"
            element={<DetailTransactionForSelf />}
          />
          <Route path="*" element={<NotFound />} />

          {routes?.map(({ path, element: Component, exact }, idx) => (
            <Route
              key={idx}
              path={path}
              exact={exact}
              element={
                <PrivateRoute>
                  <Layout>
                    <Component />
                  </Layout>
                </PrivateRoute>
              }
            />
          ))}

          <Route path="/" element={<Navigate to="/dashboard" />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
