import { useEffect } from "react";

const Index = () => {
  useEffect(() => {
    const handleTabKeyPress = (e) => {
      if (e.key === "Tab") {
        e.preventDefault(); // Tab klavishini to'xtatamiz
      }
    };

    document.addEventListener("keydown", handleTabKeyPress);

    return () => {
      document.removeEventListener("keydown", handleTabKeyPress);
    };
  }, []);
};

export default Index;
