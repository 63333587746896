import { Box, Modal, Skeleton } from "@mui/material";
import { PDFViewer } from "@react-pdf/renderer";
import { usePDF } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Check from "../../components/pdf/Check";
import useTransaction from "../../store/transaction";
import { IconClose, IconPrint, IconSearch } from "../../utils/Icons";
import PaymentConfirmCheck from "../payment/PaymentConfirmCheck";
import "../../assets/scss/_payment.scss";

const DetailTransactionsForCheck = ({ handleClose }) => {
  const {
    viewTransactionWithoutDataForCommissionMessage,
    viewTransactionWithoutDataMessage,
    viewTransactionWithoutLoading,
    viewTransactionWithoutForCommissionLoading,
    viewTransactionWithoutData,
    viewTransactionWithoutDataForCommission,
    fetchDetailTransactionWithoutToken,
    fetchDetailTransactionWithoutTokenForCommission,
  } = useTransaction();

  const [service, setService] = useState(null);
  const [commission, setCommission] = useState(null);

  let documentEl = (
    <Check
      payChequeDetailsData={viewTransactionWithoutData?.data}
      payCommissionChequeDetailsData={
        viewTransactionWithoutDataForCommission?.data
      }
    />
  );
  const [instance, updateInstance] = usePDF({ documentEl });

  const handlePrint = () => {
    window.open(instance.url, "_blank");
  };

  const handleSearch = () => {
    fetchDetailTransactionWithoutToken({ transactionId: service });
    fetchDetailTransactionWithoutTokenForCommission(commission);
  };

  useEffect(() => {
    updateInstance(documentEl);
  }, [viewTransactionWithoutData, viewTransactionWithoutDataForCommission]);

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal payment-modal">
        {/* <div className="modal-header">
          <div className="header-title">Транзакция</div>
          <button onClick={handleClose}>
            <IconClose />
          </button>
        </div> */}
        <div className="modal-body">
          <div className="flex items-end justify-center gap-4 mb-4">
            <div>
              <div className="text-sm mb-1">Партнер id (УСЛУГА)</div>
              <input
                onChange={(e) => setService(e.target.value)}
                value={service}
                type="number"
                className="input"
              />
            </div>
            <div>
              <div className="text-sm mb-1">Партнер id (КОМИССИЯ)</div>
              <input
                onChange={(e) => setCommission(e.target.value)}
                value={commission}
                type="number"
                className="input"
              />
            </div>
            <div>
              <button
                className="button button-primary [&>svg>path]:stroke-[#fff]"
                onClick={handleSearch}
              >
                <IconSearch />
                Искать
              </button>
            </div>
          </div>
          <div
            className="modal-info border-t"
            style={{ borderColor: "#8b8b8b" }}
          >
            {instance.loading ||
            viewTransactionWithoutForCommissionLoading ||
            viewTransactionWithoutLoading ? (
              <Skeleton
                className="!transform-none"
                height={"42px"}
                width={"146px"}
              />
            ) : (
              <button
                disabled={
                  viewTransactionWithoutData?.data === {} &&
                  viewTransactionWithoutDataForCommission?.data === {}
                    ? true
                    : false
                }
                className="info-button"
                onClick={handlePrint}
              >
                <IconPrint />
                Распечатать
              </button>
            )}
          </div>
          {viewTransactionWithoutDataMessage && (
            <div className="text-center">
              Услуга: {viewTransactionWithoutDataMessage}
            </div>
          )}
          {viewTransactionWithoutDataForCommissionMessage && (
            <div className="text-center">
              Комиссия: {viewTransactionWithoutDataForCommissionMessage}
            </div>
          )}
          {viewTransactionWithoutData?.data && (
            <PaymentConfirmCheck item={viewTransactionWithoutData?.data} />
          )}
          {viewTransactionWithoutDataForCommission?.data && (
            <PaymentConfirmCheck
              item={viewTransactionWithoutDataForCommission?.data}
            />
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default DetailTransactionsForCheck;
